<template>
    <div v-bind="$attrs" class="task-history">
        <div class="fw-bold mb-2">
            <CIcon name="cilDocument"/>
            History
        </div>
        <div v-for="activity in projectActivities" class="record">
            <div v-if="exists(activity.type)" class="text-information small mt-1 px-2 py-1">
                <div class="">{{ dictionary[activity.type] }} by {{ activity.user_name }}</div>
                <div class="px-4">
                    on {{ formatDateTime(activity.created_at, 'MM/DD/YYYY [at] HH:mm') }}.
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import dateFormater from "@/mixin/dateFormater";

export default {
    name: 'TaskHistory',
    mixins: [dateFormater],
    props: {
        projectActivities: Array,
    },
    data() {
        return {
            dictionary: {
                'Project-Scope-Contract-Sent': 'Contract sent',
                'Project-Task-File-Preview': 'Contract viewed',
                'Project-Scope-Contract-Fully-Executed': 'Contract signed',
                'Project-Task-Upload-File-Preview': 'Contract viewed',
                'Project-Task-Upload-File-Delete': 'Contract deleted',
                'Project-Scope-Contract-Externally-Signed': 'Contract signed',
            }
        }
    },
    methods: {
        exists(type) {
            return this.dictionary[type] !== undefined;
        }
    }
}
</script>

<style scoped lang="scss">
.task-history {
    padding: 16px 16px 24px;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    max-height: 400px;
    overflow-y: scroll;

    .record:nth-child(odd) {
        background-color: #ffffff;
    }

    .record:nth-child(even) {
        background-color: #f9f9f9;
    }

}
</style>
