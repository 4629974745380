<template>
    <TaskPageWrapper :task="task" :instructions="taskPageInstructions" @update="loadTask">
        <TaskPageDocumentFileArea v-if="isFullyExecuted || (isRevoked && task.latest_upload)" title="Signed Contract"
                                  color="green" icon="check-green" :class="{'mb-3': isRevoked}"
                                  :document-name="task.file_name"
                                  @linkClicked="toggleDocumentPreview('preview-uploaded-file', 'default')">
            <LoadingButton v-if="isFullyExecuted" :loading="loadingTaskFileDownload" :disabled="loadingTaskFileDownload"
                           color="primary" variant="outline" @click="downloadTaskUploadedFile">
                Download
            </LoadingButton>
            <LoadingButton v-if="isFullyExecuted && isTaskGeneralContractor" :loading="loadingTaskUploadDelete"
                           :aria-disabled="loadingTaskUploadDelete" color="danger" variant="outline"
                           @click="confirmTaskUploadDelete('signed contract', () => loadTask())">
                Delete Signed Contract
            </LoadingButton>
        </TaskPageDocumentFileArea>
        <TaskPageDocumentFileArea title="Sent Contract" color="blue" icon="document" class="mb-3"
                                  :document-name="task.file_name" @linkClicked="previewContractSent">
            <LoadingButton v-if="!isFullyExecuted" :loading="loadingTaskFileDownload"
                           :disabled="loadingTaskFileDownload" color="primary" variant="outline"
                           @click="downloadCurrentContract">
                Download
            </LoadingButton>
            <TaskReminderButton :task="task"/>
            <CButton v-if="isTaskGeneralContractor && isPendingExternalSignature" color="primary" variant="outline"
                     @click="toggleNewContractVersion">
                Upload new contract version
            </CButton>
            <CButton v-if="isTaskGeneralContractor && isPendingExternalSignature" color="primary" variant="outline"
                     @click="toggleUploadSignedContract">
                Upload signed contract
            </CButton>
            <CButton v-if="isReadyForExternalSignature || isReadyForInternalSignature" color="primary"
                     @click="toggleTaskESignatureModal(null)">
                Sign the contract
            </CButton>
        </TaskPageDocumentFileArea>

        <TaskPageDocumentFileArea v-if="newContractVersion" title="New Contract Version" color="blue"
                                  :task-type="task.type" :upload="true" :loading="loading" @submit="updateTaskFile">
            <p class="text-error small position-absolute">{{ newContractVersionError }}</p>
        </TaskPageDocumentFileArea>

        <TaskPageDocumentFileArea v-if="uploadSignedContract" title="Upload Signed Contract" color="blue"
                                  :task-type="task.type" :upload="true" :loading="loading" :submit-button="false"
                                  @change="file => toggleTaskESignatureModal(file)"/>

        <div v-if="task.task_file_updates.length > 0" class="mt-4">
            <TaskFileUpdates :task-id="task.id" :task-file-name="task.file_name"
                             :task-file-updates="task.task_file_updates"/>
        </div>

        <template #right-side>
            <TaskHistory v-if="task.project_activities" :project-activities="task.project_activities" class="my-4"/>
        </template>
    </TaskPageWrapper>
    <DocumentPreviewer :visible="documentPreviewer.visible" previewer="third-party" type="tasks" :type-id="taskId"
                       :route="documentPreviewer.route" :document-name="task?.file_name"
                       @closed="toggleDocumentPreview"/>
    <TaskESignatureModal :visible="taskESignatureModal" :task="task" :file="taskESignatureModalFile"
                         @close="toggleTaskESignatureModal(null)" @sign="loadTask"
                         @upload="loadTask(); toggleUploadSignedContract()"
                         @cancel-upload="toggleUploadSignedContract"/>
    <ConfirmDialog ref="confirmDialog"/>
</template>

<script>
import ConfirmDialog from '@/components/Modals/ConfirmDialog'
import TaskPageWrapper from "@/components/TaskPages/TaskPageWrapper.vue";
import Tasks from "@/api/v2/endpoints/Tasks";
import taskHelper from "@/mixin/taskHelper";
import TaskPageDocumentFileArea from "@/components/TaskPages/TaskPageDocumentFileArea.vue";
import LoadingButton from "@/components/LoadingButton.vue";
import TaskESignatureModal from "@/components/TaskPages/TaskESignatureModal.vue";
import DocumentPreviewer from "@/components/Documents/DocumentPreviewer.vue";
import apiErrorHandler from "@/mixin/apiErrorHandler";
import TaskFileUpdates from "@/components/TaskPages/TaskFileUpdates.vue";
import TaskHistory from "@/components/TaskPages/TaskHistory.vue";
import TaskReminderButton from "@/components/Task/TaskReminderButton.vue";

export default {
    name: 'Contracts',
    components: {
        TaskReminderButton,
        TaskHistory,
        TaskFileUpdates,
        ConfirmDialog,
        DocumentPreviewer,
        TaskESignatureModal,
        TaskPageDocumentFileArea,
        TaskPageWrapper,
        LoadingButton,
    },
    mixins: [taskHelper, apiErrorHandler],
    inject: ['toast'],
    data() {
        return {
            loading: false,
            newContractVersion: false,
            newContractVersionError: null,
            uploadSignedContract: false,
            taskESignatureModal: false,
            taskESignatureModalFile: null,
            subTaskPageInstructions: [
                'If you are the signer of this contract you can use the blue button to sign it.',
                'If you need changes to the contract please reach out to the buyer.',
                'You can also download, sign, and email it back to the sender.',
            ],
            gcTaskPageInstructions: [
                'Your vendor has received the instructions to sign this contract.',
                'Then, you can sign it as well.',
            ],
            documentPreviewer: {
                previewer: null,
                route: null,
                visible: false,
            },
            taskId: null,
            task: null,
        }
    },
    created() {
        this.taskId = this.$route.params.task_id;
        this.loadTask();
    },
    methods: {
        updateTaskFile(file) {
            this.toggleLoading();
            this.clearAEH();

            Tasks
                .file(this.task.id, this.taskFileUpdateData(file))
                .then(() => {
                    this.toast('info', 'Task document has been successfully updated!');
                    this.toggleNewContractVersion();
                    this.loadTask();
                })
                .catch(response => {
                    this.handleApiError(response);
                    if (response.validation?.file) this.toast('warning', response.validation.file);
                })
                .finally(() => this.toggleLoading());
        },
        loadTask() {
            Tasks
                .show(this.taskId, {
                    task_data: this.taskPageData.toString(),
                    with_external_company: 'id,name',
                    with_internal_company: 'id,name',
                    with_company_assigner_users: 'id,full_name',
                    with_company_signer_users: 'id,full_name',
                    with_project_activities: 'all',
                    with_task_approval: 'all',
                    with_project: 'name',
                    with_latest_upload: 'all',
                    with_task_file_updates: 1
                })
                .then(response => this.task = response.data);
        },
        taskFileUpdateData(file) {
            const data = new FormData();
            data.append('file', file);

            return data;
        },
        downloadCurrentContract() {
            this.isPendingInternalSignature ? this.downloadTaskUploadedFile() : this.downloadTaskFile();
        },
        previewContractSent() {
            if (this.isReadyForExternalSignature || this.isReadyForInternalSignature)
                return this.toggleTaskESignatureModal();

            this.toggleDocumentPreview(this.isPendingInternalSignature ? 'preview-uploaded-file' : 'preview-file');
        },
        toggleDocumentPreview(route = 'preview-file', previewer = 'third-party') {
            this.documentPreviewer.route = route;
            this.documentPreviewer.previewer = previewer;
            this.documentPreviewer.visible = !this.documentPreviewer.visible;
        },
        toggleNewContractVersion() {
            this.newContractVersion = !this.newContractVersion;
        },
        toggleUploadSignedContract() {
            this.uploadSignedContract = !this.uploadSignedContract;
        },
        toggleTaskESignatureModal(file = null) {
            this.taskESignatureModal = !this.taskESignatureModal;
            this.taskESignatureModalFile = file;
        },
        toggleLoading() {
            this.loading = !this.loading;
        },
    }
}
</script>
